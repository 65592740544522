<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Vue from "vue";

export default {
  page: {
    title: "Retour des locations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Retour sur RMobility",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Retour des véhicules sur RMobility",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      ordersData: [],
      fields: [
        { key: "marque", sortable: true, label: "Marque" },
        { key: "immatri", label: "Immat." },
        { key: "message", sortable: true },
        { key: "montant", sortable: true },
        { key: "dateReservation", sortable: true, label: "Date reserv." },
        { key: "dateDepart", sortable: true, label: "Départ" },
        { key: "dateRetour", sortable: true, label: "Retour prévue" },
        { key: "action", sortable: true },
      ],
      transactionData: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      //
      showReturnCar: false,
      infoVehicule: null,
      dateLocation: null,
      dateLocationRetour: null,
      kmRetour: 0,
      montant: 0,
      msgLaisser: "",
      estAgence: false,
      idReserve: null,
      rows: 0,
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const historiqueLocation = await apiRequest(
        "GET",
        "admin/entreprise/reservation",
        undefined,
        false
      );

      console.log("historiqueLocation:", historiqueLocation.data);

      if (historiqueLocation && historiqueLocation.data) {
        const locations = historiqueLocation.data.map((location) => {
          return {
            dateReservation: new Date(
              location.dateReservation
            ).toLocaleDateString("fr-FR"),
            dateDepart: new Date(location.dateDepart).toLocaleDateString(
              "fr-FR"
            ),
            dateRetour: new Date(location.dateRetour).toLocaleDateString(
              "fr-FR"
            ),
            montant: location.fraisReservation,
            message: location.messageLaisser,
            id: location.vehicule.id,
            marque: location.vehicule.marque,
            model: location.vehicule.model,
            immatri: location.vehicule.immatriculation,
            type: location.vehicule.types,
            statut: location.statut,
          };
        });
        this.ordersData = locations;
        this.rows = location.lenght;
      }
    },
    async retourner(row) {
      this.infoVehicule = row.id;

      const data = await apiRequest(
        "GET",
        "admin/entreprise/detail-location/?id=" + this.infoVehicule,
        {},
        false
      );
      if (data && data.data) {
        console.log("data:", data.data.vehicule);
        let info = data.data.vehicule;
        this.idReserve = info.reservation.id;
        this.dateLocation = new Date(
          info.reservation.dateDepart
        ).toLocaleDateString("fr-FR");
        this.dateLocationRetour = new Date(
          info.reservation.dateRetour
        ).toLocaleDateString("fr-FR");
        this.montant = info.reservation.fraisReservation;
        this.msgLaisser = info.reservation.messageLaisser;
        this.estAgence = info.reservation.estManuel;
        this.kmRetour = info.kmAuCompteur;

        this.showReturnCar = true;
      }
    },
    async retournerVehicule() {
      const data = await apiRequest(
        "POST",
        "admin/entreprise/retourner",
        {
          idReserve: this.idReserve,
          estAgence: this.estAgence,
          msgLaisser: this.msgLaisser,
          kmRetour: this.kmRetour,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
        this.showReturnCar = false;
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(action)="row">
                      <button
                        v-if="row.item.statut === 'ACTIF'"
                        class="btn btn-danger"
                        @click="retourner(row.item)"
                      >
                        Retourner
                      </button>
                      <!-- <button v-else class="btn btn-info" disabled>
                        
                      </button>-->
                      <span v-else style="text-align:center;">Terminer</span>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- RETOURNER UN VEHICULE -->
    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showReturnCar"
      title="Retourner un véhicule"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="retournerVehicule"
      >
        <div class="row mt-4">
          <div class="col-md-6">
            <label>Date de location</label>
            <input
              type="text"
              class="form-control"
              disabled
              v-model="dateLocation"
            />
          </div>
          <div class="col-md-6">
            <label>Date prévue de retour</label>
            <input
              type="text"
              disabled
              class="form-control"
              v-model="dateLocationRetour"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label>Kilométrage retour</label>
            <input type="text" class="form-control" v-model="kmRetour" />
          </div>
          <div class="col-md-6">
            <label for="">Montant final</label>
            <input
              type="text"
              class="form-control"
              v-model="montant"
              disabled
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <label>Commentaire</label>
            <textarea class="form-control" v-model="msgLaisser"></textarea>
          </div>
        </div>

        <button class="btn btn-success float-right mt-4" type="submit">
          Retourner
        </button>
      </form>
    </b-modal>
    <!-- FIN RETOUR VEHICULE -->
  </Layout>
</template>

<style></style>
